import React from "react"
import { TeamAnja } from "./team/TeamAnja"
import { TeamSven } from "./team/TeamSven"
import { TeamBrigitte } from "./team/TeamBrigitte"
import { TeamDaniela } from "./team/TeamDaniela"

export const TeamSection = () => (
  <>
    <section id="team" className="pb-section">
      <div className="container">
        <h1 className="title pb-section-title mb-6">Team</h1>
      </div>
      <TeamAnja />
      <TeamSven />
      <TeamBrigitte />
      <TeamDaniela />
    </section>
  </>
)
