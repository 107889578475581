import React from "react"
import daniela from "../../images/daniela-moser.jpeg"

export const TeamDaniela = ({ children }) => (
  <div className="container">
    <div className="columns mt-6">
      <div className="column is-4 has-text-centered">
        <img className="team-pic" src={daniela} alt="Daniela Moser" />
      </div>
      <div className="column is-7 is-offset-1">
        <h2 className="subtitle">Daniela Moser, MPA</h2>
      </div>
    </div>
  </div>
)
