import React from "react"
import { FaMapMarkerAlt } from "react-icons/fa"

export const MapOverlay = () => {
  return (
    <div className="contact-map-info">
      <h1 className="title pb-section-title is-hidden-mobile">Kontakt</h1>
      <a
        target="_new"
        href="https://www.google.ch/maps/place/Praxis+Burgweg+Dr.+med.+Anja+Hostettler+Streit+und+Prof.+Dr.+med.+Sven+Streit,+PhD/@46.8820263,7.622973,19z/data=!4m5!3m4!1s0x478e4bb4e2921f5d:0x1b5c8e6f80761c8a!8m2!3d46.8820751!4d7.623279"
      >
        <p className="contact-subtitle">
          <FaMapMarkerAlt /> Praxis Burgweg
        </p>
        <p className="contact-address">
          Burgweg 1, 3510 Konolfingen. Parkplätze sind vorhanden.
        </p>
      </a>
      <p className="contact-para-title">Öffnungszeiten</p>
      <p className="contact-para">
        Täglich von 7.45 bis 12.00 und von 13.30 bis 17.30 Uhr. Mittwoch
        nachmittags ist die Praxis geschlossen.
      </p>
      <p className="contact-para is-hidden-mobile">
        Ausserhalb der Praxisöffnungszeiten steht Ihnen{" "}
        <a href="https://www.medphone.ch" target="_new">
          Medphone
        </a>{" "}
        unter <a href="tel:0900576747">0900 57 67 47</a> (CHF 3.23/min) zur
        Verfügung. Dort werden Sie je nach Situation an den Notfallarzt der
        Region vermittelt, wo auch wir unsere Dienste leisten.
      </p>
    </div>
  )
}
