import React from "react"

import { FaRegClock } from "react-icons/fa"

export const Sprechstundentage = () => (
  <h3 className="team-subtitle">
    <FaRegClock />
    Sprechstundentage
  </h3>
)
