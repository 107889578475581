import React from "react"

import { FaTasks } from "react-icons/fa"

export const Schwerpunkte = () => (
  <h3 className="team-subtitle">
    <FaTasks />
    Schwerpunkt
  </h3>
)
