import React from "react"
import imgSprechzimmer from "../images/sprechzimmer2.jpg"
import imgAltModern from "../images/detail_altundmodern.jpg"
import medix from "../images/medix-bern_rgb.jpg"
import equam from "../images/equam_label.png"

export const PraxisSection = () => (
  <section id="praxis" className="pb-section">
    <div className="container">
      <h1 className="title pb-section-title">Praxis</h1>
      <div className="columns">
        <div className="column is-5">
          <img src={imgSprechzimmer} alt="Sprechzimmer" />
        </div>
        <div className="column is-6 is-offset-1">
          <h2 className="subtitle is-primary">Geschichte der Praxis Burgweg</h2>
          <p className="paragraph">
            Wir führen die Praxis in 3. Generation weiter. Gegründet wurde sie
            von Dr. med. August Wick, der 1998 die Praxis Dr. med. Sebastian
            Birrer übergab. 2019 übernahmen wir gemeinsam die Praxis.
          </p>
          <h2 className="subtitle is-primary">Praxis heute</h2>
          <p className="paragraph">
            Während die Innenarchitektur erhalten blieb, führten wir die Praxis
            in die Digitalisierung mit elektronischer Krankengeschichte und
            sicherer Ablage der Gesundheitsdaten. Zusätzlich bauten wir unsere
            Angebote aus mit Betreuung von Kindern, Manueller Medizin und
            Ultraschalluntersuchungen in der Praxis.
          </p>
        </div>
      </div>
      <div className="columns mt-6">
        <div className="column is-6">
          <h2 className="subtitle">Philosophie</h2>
          <p className="paragraph">
            Uns ist es ein Anliegen, für unsere Patienten da zu sein und eine
            klare Zuständigkeit zu gewährleisten. Deswegen werden Sie bei uns
            immer von derselben Ärztin/demselben Arzt behandelt. Auch wenn wir
            beide im Jobsharing arbeiten, gibt es die Möglichkeit von
            Notfallterminen und nach Möglichkeit Hausbesuchen. Ausserdem
            beteiligen wir uns natürlich am regionalen Ärztlichen Notfalldienst
            und können dort auf die Unterstützung von{" "}
            <a href="https://www.medphone.ch" target="_new">
              Medphone
            </a>{" "}
            zählen.
          </p>
          <p className="paragraph">
            Wir sind Partnerärzte vom Ärztenetzwerk{" "}
            <a href="https://www.medix-bern.ch" target="new">mediX</a> und teilen die Philosophie,
            dass wir als Ärzte gemeinsam für eine qualitativ hochstehende und
            kostenbewusste Medizin uns einsetzen. Die Qualität unserer Praxis
            wird von unabhängiger Stelle regelmässig überprüft und ist{" "}
            <a href="https://www.equam.ch/" target="new">
              EQUAM-zertifiziert
            </a>
            .
          </p>
          <p className="paragraph">
            Als Bewohner von Konolfingen nehmen wir auch am Gemeindeleben teil
            und engagieren uns in Altersheimen und im Samariterverein
            Konolfingen.
          </p>
        </div>
        <div className="column is-5 is-offset-1">
          <img src={imgAltModern} alt="Praxis Burgweg" />
        </div>
      </div>

      <div className="columns mt-6">
        <div className="praxis-logos column is-5">
          <a
            href="https://www.medix-bern.ch/"
            className="praxis_medix"
            target="_new"
          >
            <img width="220" src={medix} alt="Medix" className="praxis-logo" />
          </a>
          <a href="https://www.equam.ch/" target="_new">
            <img src={equam} alt="Equam" className="praxis-logo" />
          </a>
        </div>
      </div>
    </div>
  </section>
)
