import React from "react"
import img from "../images/logo_white.png"
import { FaMapMarker, FaPhone, FaClock } from "react-icons/fa"

export const HomeSection = ({ children }) => {
  return (
    <>
      <section id="home" className="hero is-primary pb-home">
        <div className="hero-body home-hero">
          <div className="container is-fluid home-container">
            <div className="columns home-columns is-marginless">
              <div className="column is-4 is-6-tablet home-sidebar">
                <img className="home-logo" src={img} alt="Praxis Burgweg" />
                <div className="home-center">
                  <h1 className="home-title is-hidden-mobile">
                    Willkommen zur Praxis Burgweg
                  </h1>
                </div>
                <div className="home-contact">
                  <FaClock className="home-icon" />
                  <span>
                    Montag bis Freitag von 7.45 bis 12.00 und von 13.30 bis 17.30 Uhr.{" "}
                  </span>
                  <FaMapMarker className="home-icon" />
                  <a className="home-phone-link" href="#contact">
                    Burgweg 1, 3510 Konolfingen
                  </a>
                  <FaPhone className="home-icon" />
                  <a className="home-phone-link" href="tel:+41317911646">
                    031 791 16 46
                  </a>
                </div>
              </div>
              <div className="column home-background-img" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
