import React from "react"
import { ContactMap } from "./contact/ContactMap"

export const ContactSection = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="container" />
      <div>
        <ContactMap />
      </div>
      <div className="container has-content-padding">
        <p className="paragraph contact-docs-para">
          Berichte/Untersuchungsresultate/alte Unterlagen bitten wir
          ausschliesslich elektronisch an{" "}
          <a href="mailto:praxisburgweg@hin.ch">praxisburgweg@hin.ch</a> zu
          schicken. Analoge Unterlagen (z.B. Röntgenbilder) werden durch die
          Patientinnen und Patienten aufbewahrt.
        </p>
      </div>
    </section>
  )
}
