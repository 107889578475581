import React from "react"
import anja from "../../images/anja.jpg"
import { BeruflicherWerdegang } from "./BeruflicherWerdegang"
import { Schwerpunkte } from "./Schwerpunkte"
import { Sprachen } from "./Sprachen"
import { Sprechstundentage } from "./Sprechstundentage"

export const TeamAnja = () => (
  <div className="container">
    <div className="columns">
      <div className="column is-4 has-text-centered p-relative">
        <img className="team-pic" src={anja} alt="Anja Hostettler" />
      </div>
      <div className="column is-7 is-offset-1">
        <h2 className="subtitle">Dr. med. Anja Hostettler Streit</h2>
        <BeruflicherWerdegang />
        <p className="paragraph">
          Staatsexamen 2008 in Bern. Ausbildung zur Allgemeinmedizinerin in den
          Spitälern Tafers und Burgdorf (Medizin und Chirurgie), in
          Hausarztpraxen in Laupen und Schmitten sowie in einer Kinderarztpraxis
          in Burgdorf.
          <br />
          Ausbildung in Manueller Medizin SAMM, Abschluss 2019.
        </p>
        <Schwerpunkte />
        <p className="paragraph">
          Ganzes Spektrum der Hausarztmedizin, Vorsorgeuntersuchungen bei
          Säuglingen und Kindern, manuelle Medizin.
        </p>
        <Sprachen />
        <p className="paragraph">Deutsch, Französisch, Englisch, Italienisch</p>
        <Sprechstundentage />
        <p className="paragraph">
          Dienstag und Freitag ganztags, Donnerstagmorgen
        </p>
      </div>
    </div>
  </div>
)
