import React from "react"

import { FaRegCommentDots } from "react-icons/fa"

export const Sprachen = () => (
  <h3 className="team-subtitle">
    <FaRegCommentDots />
    Sprachen
  </h3>
)
