import React from "react"

export const AngebotSection = ({ children }) => (
  <section id="angebot" className="pb-section angebot-section">
    <div className="container">
      <h1 className="title pb-section-title">Angebot</h1>
      <p className="paragraph">
        Wir möchten Sie am besten betreuen und beraten und setzen dabei auf eine
        ausgebaute Praxisstruktur bei uns in Konolfingen und auf ein Netzwerk
        mit guten Spezialärzten und Kliniken. Wir können die allermeisten
        Abklärungen direkt in der Praxis durchführen oder auch dank unserem
        Partner{" "}
        <a href="http://www.medics.ch" target="_new">
          Medics Labor
        </a>{" "}
        für Sie ohne Zusatzaufwand in die Praxis bringen (z.B. Langzeit-EKG).
      </p>
      <p className="paragraph">
        Wir verfügen über ein modernes Labor, EKG, Lungenfunktion, Röntgen,
        Ultraschall und bieten die medizinische Betreuung für alle Altersgruppen
        an. Dank unserem Partner{" "}
        <a href="http://www.medics.ch" target="_new">
          Medics Labor
        </a>{" "}
        können wir u.a. auch Langzeit-EKG durchführen. Dr. med. Anja Hostettler
        Streit bietet ausserdem die Betreuung von Kindern und
        Vorsorgeuntersuchungen und ist ausgebildet in{" "}
        <a href="http://www.samm.ch" target="_new">
          Manueller Medizin
        </a>
      </p>
      <p className="paragraph">
        Medikamente können unkompliziert via Dauerrezept oder direkt bei uns in
        der Praxis bezogen werden. Die Wahl steht Patientinnen und Patienten
        frei. Unsere Praxisapotheke wird regelmässig auf ihre Qualität überprüft
        und erfüllt alle Anforderungen. Unsere Medikamente werden von der Firma{" "}
        <a target="_new" href="http://www.spirig-healthcare.ch">
          Spirig
        </a>{" "}
        geliefert. Sollte ein Medikament mal nicht vor Ort sein, können Sie es
        jeweils am Vortag bis 16 Uhr bestellen (031 791 16 46 oder
        praxisburgweg@hin.ch) und meist bereits am nächsten Tag bei uns abholen.
      </p>
    </div>
    <div className="angebot-banner">
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-4-tablet is-offset-6-desktop angebot-banner-text">
            <p>
              Wir bieten auch Wundversorgung, Notfallmedizin, Reiseberatung,
              Impfungen und sinnvolle Check-up Untersuchungen an.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container angebot-consultation">
      <div className="columns is-vcentered">
        <div className="column is-2">
          <a
            className="button is-primary"
            target="_blank"
            href="https://erx.zurrose.ch/webwidget/request-form/doctors/zLAxbylxn3iLlk/medication-selection?primaryColor=%230695a0&secondaryColor=%25282a&lang=de"
          >
            Medikamententenanfrage
          </a>
        </div>
        <div className="column is-4 is-offset-6">
          <p>
            Sollten Sie einmal ein Gespräch benötigen und können nicht
            vorbeikommen, dann bieten wir auch telefonische Beratungen oder bei
            Bedarf Videokonsultationen an.
          </p>
        </div>
      </div>
    </div>
  </section>
)
