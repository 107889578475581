import React from "react"

import { IconContext } from "react-icons"

import { Navbar } from "../components/navbar"
import { HomeSection } from "../components/HomeSection"
import { PraxisSection } from "../components/PraxisSection"
import { AngebotSection } from "../components/AngebotSection"
import { TeamSection } from "../components/TeamSection"

import "../style/styles.scss"
import { ContactSection } from "../components/ContactSection"
import { Footer } from "../components/Footer"

const PraxisBurgweg = () => (
  <IconContext.Provider value={{ className: "pb-icon" }}>
    <Navbar />
    <HomeSection />
    <PraxisSection />
    <AngebotSection />
    <TeamSection />
    <ContactSection />
    <Footer />
  </IconContext.Provider>
)

export default PraxisBurgweg
