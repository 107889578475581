import React from "react"
import sven from "../../images/sven.jpg"
import { BeruflicherWerdegang } from "./BeruflicherWerdegang"
import { Schwerpunkte } from "./Schwerpunkte"
import { Sprachen } from "./Sprachen"
import { Sprechstundentage } from "./Sprechstundentage"

export const TeamSven = ({ children }) => (
  <div className="container">
    <div className="columns mt-6">
      <div className="column is-4 has-text-centered">
        <img className="team-pic" src={sven} alt="Sven Streit" />
      </div>
      <div className="column is-7 is-offset-1">
        <h2 className="subtitle">Prof. Dr. med. Sven Streit PhD</h2>
        <BeruflicherWerdegang />
        <p className="paragraph">
          Staatsexamen 2008 in Bern. Ausbildung zum Allgemeinmediziner in den
          Spitälern Münsingen, Ziegler, Inselspital Bern und einer
          Hausarztpraxis in Bern. Seit 2017 Mitarbeit in der Praxis von Dr.
          Birrer. Fähigkeitsausweis für Sonographie SGUM 2015.
        </p>
        <p>
          50%ige Tätigkeit als Professor für Hausarztmedizin in Forschung/Lehre
          am Institut für Hausarztmedizin (BIHAM) in Bern.
        </p>
        <Schwerpunkte />
        <p className="paragraph">
          Ganzes Spektrum der Hausarztmedizin, spezielle Interessen in Geriatrie
          und Polypharmazie, Ultraschall.
        </p>
        <Sprachen />
        <p className="paragraph">Deutsch, Französisch, Englisch, Spanisch</p>
        <Sprechstundentage />
        <p className="paragraph">
          Montag ganztags, Mittwochmorgen, Donnerstagnachmittag
        </p>
      </div>
    </div>
  </div>
)
