import React from "react"

import logo from "../images/logo.png"
import { Link } from "gatsby"

export const Footer = () => (
  <footer className="pb-footer">
    <div className="has-text-centered">
      <img width="100" src={logo} alt="Praxis Burgweg" />
    </div>
    <div className="container">
      <div className="pb-footer-nav">
        <a className="pb-footer-link" href="/#praxis">
          Praxis
        </a>
        <a className="pb-footer-link" href="/#angebot">
          Angebot
        </a>
        <a className="pb-footer-link" href="/#team">
          Team
        </a>
        <a className="pb-footer-link" href="/#contact">
          Kontakt
        </a>
      </div>
      <div className="pb-footer-copyright has-text-centered">
        Copyright © <span id="current-year">{new Date().getFullYear()}</span>{" "}
        Praxis Burgweg
      </div>
      <div className="has-text-centered mt-4">
        <Link className="pb-footer-link" to="/impressum">
          Impressum & Datenschutzerklärung
        </Link>
      </div>
    </div>
  </footer>
)
